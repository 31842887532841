@import './../node_modules/locomotive-scroll/dist/locomotive-scroll.css';

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Bold.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Regular.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 10px;
}

@media screen and (min-width: 420px) {
  html {
    font-size: clamp(10px, 0.8vw, 2rem);
  }
}

*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #171717;
  font-family: 'Aeonik', 'Helvetica Neue', 'Helvetica', sans-serif;
  text-transform: uppercase;
}

html.has-scroll-smooth {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: calc(var(--vh, 1vh) * 100);
}

a {
  text-decoration: none;
  color: inherit;
}

.mouse-container {
  pointer-events: none;
  transform-origin: center;
  z-index: 999999;
  width: 1px;
  height: 1px;
  border-radius: 100px;
  position: fixed;
  transform: translate(0, 0);
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse-pointer {
  pointer-events: none;
  transform-origin: center;
  border-radius: 100px;
  position: absolute;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #000000;
  mix-blend-mode: difference;
  background-blend-mode: difference;
}
